import Button from "components/common/buttons/buttons";
import { Check } from "components/common/Icons";
import CenterModal from "components/common/Modal/CenterModal";
import { useState, useEffect } from "react";
import { TYPO } from "styles/Typo";
import useModalClickOutside from "utils/useModalClickOutside";
import * as Sentry from "@sentry/nextjs";
import { useSession } from "next-auth/react";

function ServerErrorModal({
  setModalOpen,
  errorContents,
  errorId,
}: {
  setModalOpen: any;
  errorContents: any;
  errorId: string;
}) {
  const modalRef = useModalClickOutside(setModalOpen);
  const [checked, setChecked] = useState(true);
  const { data: session }: any = useSession();
  useEffect(() => {
    // sentry에 로그 전송
    console.log("errorContents", errorContents);
    Sentry.captureMessage(`[KB] ${errorId} ERROR / ${session?.user?.email}`, {
      level: "error",
      tags: {
        errorType: `[KB] ${errorId}`,
      },
      extra: { data: errorContents },
    });
  }, []);

  const handleGoBack = async () => {
    if (checked) {
      // 개발팀 연락
      try {
        await fetch("/api/kb/report", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            errorId,
            value: errorContents,
            email: session?.user?.email,
          }),
        });
      } catch (e) {
        console.log(e);
        Sentry.captureMessage(
          `[KB]${errorId} Report ERROR / ${session?.user?.email}`
        );
      }
    }
    setModalOpen(false);
  };
  return (
    <CenterModal modalRef={modalRef}>
      <div className="flex flex-col gap-5 w-full">
        <div className="flex flex-col gap-1">
          <span className={`${TYPO.TYPO5_SB} text-gray80`}>
            오류가 발생했어요
          </span>
          <span className={`${TYPO.TYPO7_RG} text-gray60`}>
            내역을 고객센터에 접수할게요
          </span>
        </div>
        <div className="flex flex-col">
          <div
            className="flex gap-2 items-center py-2 cursor-pointer"
            onClick={() => {
              setChecked((prev) => !prev);
            }}
          >
            <Check
              fill={checked ? "blue40" : "gray30"}
              width={20}
              height={20}
            />
            <span className={`${TYPO.TYPO8_RG} text-gray50`}>
              빠른 해결을 위해 개발팀의 연락을 받을게요
            </span>
          </div>
          <Button
            type="primary"
            clickHandler={() => {
              handleGoBack();
            }}
            size="xl"
            text="확인"
            width="w-full"
          />
        </div>
      </div>
    </CenterModal>
  );
}

export default ServerErrorModal;
