import Button from "components/common/buttons/buttons";
import DividerLine from "components/common/divider/line";
import TopHeader from "components/common/header";
import { CheckFill } from "components/common/Icons";
import ServerErrorModal from "components/modals/KB/ServerErrorModal";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { TYPO } from "styles/Typo";
import { agreeTermsRequest } from "utils/clientAPIs/kbAuth";
import { useIosFixedBottomButton } from "utils/ios";
import AccountCheckNoAccount from "../Account/Check/NoAccount";
import NoConnectableAccount from "../Account/Check/NoConnectableAccount";

function TermsAgreePage({
  isMobile,
  completeHandler,
  backHandler,
}: {
  isMobile: boolean;
  completeHandler?: any;
  backHandler?: any;
}) {
  const router = useRouter();
  useIosFixedBottomButton();
  const { type } = router.query;

  const { data: session }: any = useSession();
  const [selectedList, setSelectedList] = useState([false, false]);

  const [scrollEnd, setScrollEnd] = useState(false);
  const [error, setError] = useState(false);
  const [noCustomerNumber, setNoCustomerNumber] = useState(false);

  const handleScroll = () => {
    const height = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    );
    const scrollAmount = height - 48 - 100; // 한 번에 스크롤할 양
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;

    const maxScroll = scrollHeight - clientHeight;
    const newScrollTop = scrollTop + scrollAmount;

    if (newScrollTop >= maxScroll) {
      // 스크롤이 끝에 도달하면 버튼 텍스트 변경
      setScrollEnd(true);
      window.scrollTo({ top: maxScroll, behavior: "smooth" }); // 부드럽게 스크롤
    } else {
      window.scrollTo({ top: newScrollTop, behavior: "smooth" });
    }
  };
  const handleManualScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
    const maxScroll = scrollHeight - clientHeight;

    if (scrollTop >= maxScroll - 50) {
      // 사용자가 직접 스크롤해서 끝에 도달했는지 확인
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  };

  useEffect(() => {
    // 스크롤 이벤트 리스너 추가
    window.addEventListener("scroll", handleManualScroll);

    return () => {
      // 컴포넌트가 언마운트될 때 리스너 제거
      window.removeEventListener("scroll", handleManualScroll);
    };
  }, []);

  const handleAgree = async () => {
    if (!session?.user?.email) {
      router.push("/login");
      return;
    }
    setSelectedList([true, true]);

    // 약관 동의 로직

    try {
      const status = await agreeTermsRequest(
        session?.user?.email,
        type as "new" | "exist" | undefined
      );
      console.log("status", status);
      if (status === 200) {
        if (completeHandler) {
          setTimeout(() => {
            completeHandler();
          }, 500);
        }
      } else {
        if (status === 404) {
          // type === 'exist' 인데 고객 번호 없을 때
          setNoCustomerNumber(true);
        } else {
          throw new Error("약관 동의 실패");
        }
      }
    } catch (e: any) {
      // setError(e);
      console.log("고객 번호 없", e);
      setError(e);
    }
  };

  if (noCustomerNumber && type === "exist") {
    return (
      <main
        className={`min-w-[360px] max-w-[800px] w-screen bg-white min-h-screen`}
      >
        <NoConnectableAccount
          isMobile={true}
          backHandler={() => {
            setNoCustomerNumber(false);
          }}
        />
      </main>
    );
  }

  return (
    <main
      className={`min-w-[360px] max-w-[800px] w-screen bg-white min-h-screen`}
    >
      <TopHeader
        clickHandler={() => {
          if (backHandler) {
            backHandler();
            return;
          }
          router.back();
        }}
      />
      <div className="px-6 pt-6 mb-6">
        <span className={`${TYPO.TYPO3_SB} text-gray90`}>
          계좌를 연결하기 위한
          <br />
          동의문이에요
        </span>
      </div>
      <div className="flex flex-col">
        <div className="px-6 py-2">
          <span className={`${TYPO.TYPO5_SB} text-gray80`}>
            {`개인(신용)정보 수집·이용·제공 동의서`}
            <br />
            {`(지엔이테크홀딩스 제휴서비스)`}
          </span>
        </div>
        <div className="px-6 pt-6">
          <span className={`${TYPO.TYPO5_SB} text-gray80`}>
            수집·이용에 관한 사항
          </span>
        </div>
        <div className="py-2 flex flex-col gap-2 ">
          <div className="px-6 py-2 flex flex-col gap-2">
            <span className={`${TYPO.TYPO7_SB} text-gray80`}>
              수집·이용 목적
            </span>
            <ul className={`${TYPO.TYPO7_RG} text-gray60 list-disc pl-5`}>
              <li>
                {`㈜지엔이테크홀딩스가 운영하는 ‘리스크웨더’ 앱 내 비대면 계좌개설
                및 매매서비스 이용`}
              </li>
              <li>KB증권 오픈API 서비스 이용</li>
              <li>금융사고 조사, 분쟁 해결, 민원 처리, 법령상 의무 이행</li>
            </ul>
          </div>
          <div className="px-6 py-2 flex flex-col gap-2">
            <span className={`${TYPO.TYPO7_SB} text-gray80`}>
              보유 및 이용기간
            </span>
            <span className={`${TYPO.TYPO7_RG} text-gray60`}>
              {`리스크웨더 앱 회원 탈퇴 시 또는 계좌 연동 해지 시까지 (단, 다른
              관련 법령에 해당하는 경우 해당 법령상의 보존기간을 따름)`}
            </span>
          </div>
          <div className="px-6 py-2 flex flex-col gap-2">
            <span className={`${TYPO.TYPO7_SB} text-gray80`}>
              거부 권리 및 불이익
            </span>
            <span className={`${TYPO.TYPO9_RG} text-gray60`}>
              {`귀하는 아래 개인(신용)정보의 수집·이용에 대해 거부하실 수
              있습니다. 다만, “수집·이용에 관한 동의”는 리스크웨더 제휴서비스
              이용을 위한 필수적 사항이므로 동의를 거부하실 경우 당사 계좌를
              통한 제휴서비스가 제한될 수 있습니다.`}
            </span>
          </div>
          <div className="px-6 py-2 flex flex-col gap-2">
            <span className={`${TYPO.TYPO7_SB} text-gray80`}>
              {`수집·이용 항목`}
            </span>
            <ul className={`${TYPO.TYPO7_RG} text-gray60 list-disc pl-5`}>
              <li>일반개인정보 : 연계정보CI</li>
              <li>
                {`일반개인정보 : 연계정보CI 신용거래정보 : 계좌번호, 주문내역,
                체결내역, 총자산, 예수금, 주문가능금액, 출금가능금액, 미수금,
                보유잔고, 주식평가금액, 실현손익, 수익률정보`}
              </li>
            </ul>
          </div>
        </div>
        <DividerLine size="lg" />
        <div className="px-6 pt-6">
          <span className={`${TYPO.TYPO5_SB} text-gray80`}>
            제공에 관한 사항
          </span>
        </div>
        <div className="py-2 flex flex-col gap-2 mb-4">
          <div className="px-6 py-2 flex flex-col gap-2">
            <span className={`${TYPO.TYPO7_SB} text-gray80`}>제공받는 자</span>
            <span className={`${TYPO.TYPO7_RG} text-gray60`}>
              {`㈜지엔이테크홀딩스가 운영하는 ‘리스크웨더’ 앱 내 KB증권 비대면
              계좌개설 및 매매서비스 이용`}
            </span>
          </div>
          <div className="px-6 py-2 flex flex-col gap-2">
            <span className={`${TYPO.TYPO7_SB} text-gray80`}>
              제공받는자의 보유및이용기간
            </span>
            <ul className={`${TYPO.TYPO7_RG} text-gray60 list-disc pl-5`}>
              <li>{`연계정보(CI) : 서비스 연동을 위한 고객 식별 후 즉시 파기`}</li>
              <li>
                {`신용거래정보 : 리스크웨더 앱 회원 탈퇴 시 또는 계좌 연동 해지
                시까지 (단, 다른 관련 법령에 해당하는 경우 해당 법령상의
                보존기간을 따름)`}
              </li>
            </ul>
          </div>
          <div className="px-6 py-2 flex flex-col gap-2">
            <span className={`${TYPO.TYPO7_SB} text-gray80`}>
              거부 권리 및 불이익
            </span>
            <span className={`${TYPO.TYPO9_RG} text-gray60`}>
              {`귀하는 아래 개인(신용)정보의 제공에 대해 거부하실 수 있습니다.
              다만, “개인(신용)정보 제공 동의”는 리스크웨더 제휴서비스 이용을
              위한 필수적 사항이므로 동의를 거부하실 경우 당사 계좌를 통한
              제휴서비스가 제한될 수 있습니다.`}
            </span>
          </div>
          <div className="px-6 py-2 flex flex-col gap-2">
            <span className={`${TYPO.TYPO7_SB} text-gray80`}>제공 항목</span>
            <ul className={`${TYPO.TYPO7_RG} text-gray60 list-disc pl-5`}>
              <li>일반개인정보 : 연계정보CI</li>
              <li>
                {`신용거래정보 : 계좌번호, 주문내역, 체결내역, 총자산, 예수금,
                주문가능금액, 출금가능금액, 미수금, 보유잔고, 주식평가금액,
                실현손익, 수익률정보`}
              </li>
            </ul>
          </div>
          <div className="px-6 py-2 ">
            <span className={`${TYPO.TYPO9_RG} text-gray50`}>
              {`KB증권귀중`}
              <br />
              {`㈜지엔이테크홀딩스가 운영하는 모바일 어플리케이션
              ‘리스크웨더’를 통한 제휴서비스 이용과 관련하여 [귀 사]가 본인의
              개인(신용)정보를 수집·이용·제공 하는 경우에는 「신용정보의 이용 및
              보호에 관한 법률」, 「개인정보 보호법」등 관계 법령에 따라 본인의
              동의가 필요합니다.`}
            </span>
          </div>
        </div>
        <div className="px-6 py-2">
          <span className={`${TYPO.TYPO5_SB} text-gray80`}>
            {`금융거래정보 등의 제공사실 통지 동의서`}
            <br />
            {`(KB증권오픈API 서비스용)`}
          </span>
        </div>
        <div className="py-2 flex flex-col gap-2">
          <div className="px-6 py-2 flex flex-col gap-2">
            <span className={`${TYPO.TYPO7_SB} text-gray80`}>
              통지할 정보 등의 내용
            </span>
            <span className={`${TYPO.TYPO7_RG} text-gray60`}>
              {`KB증권 오픈API 서비스를 통해 KB증권에 요청한 본인의 금융거래 정보`}
            </span>
          </div>
          <div className="px-6 py-2 flex flex-col gap-2">
            <span className={`${TYPO.TYPO7_SB} text-gray80`}>
              정보 등을 제공 및 사실통지 금융기관
            </span>
            <span className={`${TYPO.TYPO7_RG} text-gray60`}>{`KB증권`}</span>
          </div>
          <div className="px-6 py-2 flex flex-col gap-2">
            <span className={`${TYPO.TYPO7_SB} text-gray80`}>
              거부 권리 및 불이익
            </span>
            <span
              className={`${TYPO.TYPO7_RG} text-gray60`}
            >{`귀하는 동의를 거부하실 수 있습니다. 다만 위 금융거래정보 등의 제공사실 통지 동의는 KB증권 오픈API를 이용하기 위해 필수적이므로, 위 사항에 동의하셔야만 서비스를 이용하실 수 있습니다.`}</span>
          </div>
          <div className="px-6 py-2 flex flex-col gap-2">
            <span className={`${TYPO.TYPO7_SB} text-gray80`}>
              금융거래정보 등의 제공하실 통지 동의 여부
            </span>
            <span
              className={`${TYPO.TYPO7_RG} text-gray60`}
            >{`귀사가 위와 같이 본인의 제공된 금융거래정보 등을 일괄 통보하는 것에 동의하십니까?`}</span>
          </div>
          <div className="px-6 py-2 ">
            <span className={`${TYPO.TYPO9_RG} text-gray50`}>
              {`KB증권귀중`}
              <br />
              {`KB증권은 오픈 API 서비스를 제공하기 위하여 고객님의 신용정보 및 금융거래에 관한 정보 등을 서비스 제공자에게 제공한 경우에는 이에 관하여 『금융실명거래 및 비밀보장에 관한 법률』 제 4조의 2에 따라 서비스 제공자에 대한 금융거래정보 등 포괄적 정보제공 및 해당 정보제공 내역을 6개월 단위로 일괄 통보하는 것에 대해서 본인의 동의가 필요합니다. `}
            </span>
          </div>
        </div>
        <DividerLine size="lg" />
        <div className="px-6 pt-6 flex flex-col gap-2 mb-4">
          <span className={`${TYPO.TYPO7_SB} text-gray80`}>
            필수 항목에 모두 동의하기
          </span>
          <span className={`${TYPO.TYPO9_RG} text-gray60`}>
            {`개인(신용)정보수집·이용에 동의, 개인(신용)정보제공에 동의, 금융거래정보 등의 제공사실 통지 동의에 모두 동의합니다.`}
          </span>
        </div>
      </div>
      <div className="px-6 py-2 flex flex-col gap-4 pb-[130px]">
        <div
          className="flex items-center gap-2 p-4 bg-gray2 rounded-[16px] border border-gray20"
          onClick={() => {
            let temp = [...selectedList];
            temp[0] = !temp[0];
            setSelectedList(temp);
          }}
        >
          <div>
            <CheckFill
              fill={selectedList[0] ? "blue40" : "gray30"}
              height={24}
              width={24}
            />
          </div>
          <span className={`${TYPO.TYPO5_1_SB} text-gray80`}>
            금융거래정보 등의 제공사실 통지 동의
          </span>
        </div>
        <div
          className="flex items-center gap-2 p-4 bg-gray2 rounded-[16px] border border-gray20"
          onClick={() => {
            let temp = [...selectedList];
            temp[1] = !temp[1];
            setSelectedList(temp);
          }}
        >
          <div>
            <CheckFill
              fill={selectedList[1] ? "blue40" : "gray30"}
              height={24}
              width={24}
            />
          </div>
          <span className={`${TYPO.TYPO5_1_SB} text-gray80`}>
            개인(신용)정보 수집·이용·제공 동의
          </span>
        </div>
      </div>
      <div
        className={
          isMobile
            ? ` z-[99] bg-white w-full px-5 pt-4 pb-[30px] fixed bottom-0 left-0 !font-semibold flex flex-col gap-4 `
            : `z-[99] bg-white w-[800px] px-5 pt-4 pb-[30px] fixed bottom-0 left-[50%] translate-x-[-50%] !font-semibold flex flex-col gap-4 `
        }
      >
        <Button
          type="primary"
          size="lg"
          clickHandler={() => {
            if (scrollEnd) handleAgree();
            else handleScroll();
          }}
          text={scrollEnd ? "모두 동의하기" : "아래로 스크롤하기"}
        />
      </div>
      {error && (
        <ServerErrorModal
          errorId={"KT01"}
          errorContents={error}
          setModalOpen={setError}
        />
      )}
    </main>
  );
}

export default TermsAgreePage;
